import React from 'react';

const SkillsPage = () => {
    return (
        <section>
            <h2>Skills</h2>
            <ul>
                <li>Java (Spring Framework, Object-Oriented Programming, Design Patterns, Clean Code, SOLID)</li>
                <li>Go (Go-chi, GORM, Goose)</li>
                <li>Python (Django, Flask, REST API)</li>
                <li>JavaScript (AngularJS, Vue.js, React, Redux, jQuery)</li>
                <li>SQL (MySQL, PostgreSQL, MongoDB, CassandraDB, CockroachDB, InfluxDB)</li>
                <li>DevOps (Docker, Kubernetes, Rancher, Vagrant, Packer, Terraform, Jenkins, CircleCI, Git)</li>
                <li>Cloud Computing (AWS, GCP, Azure)</li>
                <li>Web Frameworks (Spring, Flask, Echo, Play)</li>
                <li>Database Tools (pgAdmin, MySQL Workbench, MongoDB Compass, DataGrip)</li>
                <li>Front-end Tools (Hugo, HTML5, CSS (LESS, SASS))</li>
                <li>Text Editors and IDEs (Visual Studio Code, Eclipse, PyCharm, IntelliJ IDEA, Vim, GoLand, WebStorm)</li>
                <li>Networking (Computer Networks, TCP/IP)</li>
                <li>Containers (Docker, Kubernetes)</li>
                <li>Object-Oriented Programming (Clean Code, SOLID, KISS, DRY, YAGNI)</li>
                <li>Software Development Methodologies (Agile, Scrum)</li>
                <li>Security (SOC, ACID, OWASP Top 10, Burp Suite, Wireshark)</li>
            </ul>
        </section>
    );
};

export default SkillsPage;
