import React from 'react';

const AboutPage = () => {
    return (
        <section>
            <h2>About Kacper Bąk</h2>
            <p>As a developer, I have worked on various projects and have traveled extensively throughout Poland. I am currently based in Katowice and am always looking for new challenges.</p>
            <p>My expertise lies in developing efficient and scalable backend applications using Linux and Go-based tools and packages. I am also well-versed in cloud solutions and system architecture.</p>
            <h3>More</h3>
            <ul>
                <li>Feel free to ask me anything on <a href="https://github.com/53jk1/ama" target="_blank" rel="noopener noreferrer">GitHub</a></li>
            </ul>
            <h3>Links</h3>
            <ul>
                <li><a href="https://twitter.com/KacperBk18" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                <li><a href="https://mastodon.social/@53jk1" target="_blank" rel="noopener noreferrer">Mastodon</a></li>
                <li><a href="https://github.com/53jk1" target="_blank" rel="noopener noreferrer">GitHub</a></li>
                <li><a href="https://www.linkedin.com/in/kacper-bąk-178a9126b/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                <li><a href="https://medium.com/@53jk1" target="_blank" rel="noopener noreferrer">Medium</a></li>
            </ul>
        </section>
    );
};

export default AboutPage;
